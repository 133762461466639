import React, {useCallback, useEffect, useState} from "react";
import ResidentCard, { Resident } from "../components/ResidentCard";
import { useNavigate } from "react-router-dom";
import MobileMenu from "../components/MobileMenu";
import ResidentFilter from "../components/ResidentFilter";
import { observer } from "mobx-react-lite";
import residentsStore from "../../providers/store/residentsStore";

const ResidentsPage = () => {
  const navigate = useNavigate();
  const [residents, setResidents] = useState(residentsStore.residents);

  const goToResident = ({ id }: Resident) => {
    navigate(`/residents/${id}`);
  };

  const handleFilter = useCallback((searchText: string) => {
    const text = searchText.toLowerCase();
    const result = residentsStore.residents.filter(({ fio }) =>
      fio.toLowerCase().includes(text),
    );
    setResidents(result);
  }, []);
  useEffect(() => {
    document.title = "Busines League";
  }, []);
  return (
    <div className="p-4 m-auto" style={{ maxWidth: 870 }}>
      <div className="grid">
        <div className="col-12">
          <ResidentFilter onChange={handleFilter} />
        </div>

        {residents.map((resident) => (
          <div className="col-12" key={resident.id}>
            <ResidentCard resident={resident} onPress={goToResident} />
          </div>
        ))}
      </div>

      <MobileMenu />
    </div>
  );
};

export default observer(ResidentsPage);
