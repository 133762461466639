import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { debounce } from "lodash";

type PropsTypes = {
  onChange: (fio: string) => void;
};

const ResidentFilter = ({ onChange }: PropsTypes) => {
  const [value, setValue] = useState("");

  const delayedLog = useCallback(
    debounce((value: string) => {
      onChange(value);
    }, 600),
    [],
  );

  useEffect(() => {
    delayedLog(value);
  }, [value]);

  return (
    <label className="p-input-icon-right flex">
      <InputText
        className="col-12"
        placeholder="Поиск ФИО"
        value={value}
        onInput={(el) => setValue(el.currentTarget.value)}
      />
      {value.length ? (
        <i
          className="pi pi-times cursor-pointer"
          onClick={() => setValue("")}
        />
      ) : (
        ""
      )}
    </label>
  );
};

export default ResidentFilter;
