import React, {useEffect, useRef, useState} from "react";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import residentsStore from "../../providers/store/residentsStore";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";

const ResidentPage = () => {
  let { id } = useParams();

  const navigate = useNavigate();
  const goToMain = () => {
    navigate(-1);
  };

  const [resident] = useState(
    residentsStore.residents.find(
      ({ id: idPerson }) => idPerson === +(id as string),
    ),
  );

  useEffect(() => {
    if (resident) {
      document.title = resident.fio;
    }
  }, []);

  if (!resident) {
    return <div></div>;
  }

  // ToDo - исправить
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const toast = useRef(null);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);

    // @ts-ignore ToDo - исправить
    toast.current.show({
      severity: "info",
      detail: "Ссылка скопирована",
      closable: false,
      life: 3000,
      icon: "pi pi-check",
    });
  };

  return (
    <div className="p-4 m-auto ResidentPage" style={{ maxWidth: 870 }}>
      <Toast ref={toast} position="bottom-center" />

      <Button icon="pi pi-arrow-left" onClick={goToMain} size="small" rounded />

      <Card className="mt-4">
        <div className="grid">
          <div className="col-12 flex justify-content-center">
            <Avatar
              image={resident.img}
              shape="circle"
              style={{ width: 200, height: 200 }}
            />
          </div>

          <div className="col-12 text-3xl font-semibold text-yellow-600 text-center">
            <span>{resident.fio}</span>
          </div>

          <div className="col-12 text-center">
            <Button
              icon="pi pi-link"
              label="Ссылка на профиль"
              severity="info"
              size="large"
              text
              onClick={handleCopyLink}
            />
          </div>

          <div className="col-12">
            <div className="text-center underline text-lg text-yellow-600">
              Главная
            </div>

            <div className="text-left text-sm text-yellow-600 mt-4">Должность</div>
            <div className="text-left font-medium mt-2">{resident.jobTitle}</div>

            <div className="text-left text-sm text-yellow-600 mt-4">Комания</div>
            <div className="text-left font-medium mt-2">{resident.company}</div>

            <div className="text-left text-sm text-yellow-600 mt-4">Описание</div>
            <div className="text-left font-medium mt-2">{resident.description}</div>
          </div>
        </div>

        {/*<TabView>*/}
        {/*  <TabPanel header="Главное">*/}
        {/*    <div className="Info m-0">*/}
        {/*      <div className="Info-header flex flex-wrap justify-content-between align-items-center ">*/}
        {/*        <div className="Header-title">*/}
        {/*          <h3>О Вас</h3>*/}
        {/*        </div>*/}
        {/*        <div className="Header-edit">*/}
        {/*          <Button icon="pi pi-pencil" />*/}
        {/*        </div>*/}
        {/*        /!*<div className="Header-progressBar min-w-full">*!/*/}
        {/*        /!*  <ProgressBar value={95}></ProgressBar>*!/*/}
        {/*        /!*  <h4>Ваш профиль заполнен на {resident.value}%</h4>*!/*/}
        {/*        /!*</div>*!/*/}
        {/*      </div>*/}

        {/*      <div className="Info-field mt-4">*/}
        {/*        <div className="Field flex flex-column gap-2">*/}
        {/*          <label htmlFor="username" className="Field-text">*/}
        {/*            Должность*/}
        {/*          </label>*/}
        {/*          <div id="username" className="Field-info m-0">*/}
        {/*            {resident.jobTitle}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      /!*<div className="Info-field mt-4">*!/*/}
        {/*      /!*  <div className="Field flex flex-column gap-2">*!/*/}
        {/*      /!*    <label htmlFor="username" className="Field-text">*!/*/}
        {/*      /!*      ИНН*!/*/}
        {/*      /!*    </label>*!/*/}
        {/*      /!*    <div id="username" className="Field-info m-0">*!/*/}
        {/*      /!*      {resident.inn}*!/*/}
        {/*      /!*    </div>*!/*/}
        {/*      /!*  </div>*!/*/}
        {/*      /!*</div>*!/*/}
        {/*      <div className="Info-field mt-4">*/}
        {/*        <div className="Field flex flex-column gap-2">*/}
        {/*          <label htmlFor="username" className="Field-text">*/}
        {/*            Описание*/}
        {/*          </label>*/}
        {/*          <div id="username" className="Field-info m-0">*/}
        {/*            {resident.description}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      /!*<div className="Info-field mt-4">*!/*/}
        {/*      /!*  <div className="Field flex flex-column gap-2">*!/*/}
        {/*      /!*    <label htmlFor="username" className="Field-text">*!/*/}
        {/*      /!*      Чем могу помочь*!/*/}
        {/*      /!*    </label>*!/*/}
        {/*      /!*    <div id="username" className="Field-info m-0">*!/*/}
        {/*      /!*      {resident.canHelp}*!/*/}
        {/*      /!*    </div>*!/*/}
        {/*      /!*  </div>*!/*/}
        {/*      /!*</div>*!/*/}
        {/*      /!*<div className="Info-field mt-4">*!/*/}
        {/*      /!*  <div className="Field flex flex-column gap-2">*!/*/}
        {/*      /!*    <label htmlFor="username" className="Field-text">*!/*/}
        {/*      /!*      В чем нужна помощь*!/*/}
        {/*      /!*    </label>*!/*/}
        {/*      /!*    <div id="username" className="Field-info m-0">*!/*/}
        {/*      /!*      {resident.needHelp}*!/*/}
        {/*      /!*    </div>*!/*/}
        {/*      /!*  </div>*!/*/}
        {/*      /!*</div>*!/*/}
        {/*    </div>*/}
        {/*  </TabPanel>*/}
        {/*  <TabPanel header="Контакты"></TabPanel>*/}
        {/*  <TabPanel header="Настройки"></TabPanel>*/}
        {/*</TabView>*/}
      </Card>
    </div>
  );
};
export default ResidentPage;
