import { makeAutoObservable } from "mobx";

class ResidentsStore {
  residents = [
    {
      id: 1,
      img: "/assets/images/residents/1.png",
      fio: "Лаврентьев Константин Евгеньевич",
      jobTitle: "Учредитель, исполнительный директор",
      company:
        "Калиновские электрические сети. Электроснабжение под ключ. Энергоаудит.",
      description: `«Калиновские электрические сети» — это территориальная электросетевая организация, ведущая свою деятельность на территории городских округов Серпухов, Протвино, Пущино, Чехов, Подольск, Раменское. Мы находимся в городском округе Серпухов, вблизи поселка Большевик. Основным направлением деятельности компании является передача электроэнергии по собственным электросетям и технологическое присоединение новых потребителей.                        Мы входим в реестры энергоснабжающих организаций Московской области и естественных монополий топливно-энергетического комплекса России. В отношении компании осуществляется государственное регулирование и контроль.`,
    },
    {
      id: 2,
      img: "/assets/images/residents/2.png",
      fio: "Медведева Елена  Николаевна ",
      jobTitle: "Учредитель , Генеральный директор ",
      company: "Рекламно -производственная компания ООО « ОЕС» ",
      description: `ООО «ОЕС» - рекламно-производственная компания, основанная в 2010 году. Компания занимается рекламной деятельностью в регионах РФ, МО, Серпухов. Основной лозунг компании - «От идеи до воплощения», мы беремся за самые сложные и креативные проекты. Компания выполняет разработку брендбуков, фирменного стиля, производство наружной и внутренней рекламы, комплексное оснащение торговых центров системой навигации, изготовление дорожных указателей и знаков, разработку фирменного стиля предприятия и установку в едином стиле информационных стендов. Кроме того, компания занимается производством сувенирной и полиграфической продукции.`,
    },
    {
      id: 3,
      img: "/assets/images/residents/3.png",
      fio: "Омельницкий Сергей Игоревич",
      jobTitle: "Founder, Генеральный директор",
      company: "Takeoff staff",
      description: `Мы открываем новые возможности в масштабировании и оптимизации бизнес-процессов за счет внедрения индивидуальных IT-решений и команды опытных специалистов. Наша сильная сторона это разработка клиентской части ПО. Мы специализируемся на реализации SPA приложений в b2b сегменте, а так же помогаем найти классных ИТ специалистов.`,
    },
    {
      id: 4,
      img: "/assets/images/residents/4.png",
      fio: "Ефимочкина Ольга Владимировна",
      jobTitle: "Владелица бизнеса",
      company: "Женская одежда Серпухов | ГРАЦИЯ",
      description: `Наша цель - помочь женщинам не только выглядеть красиво, но и чувствовать себя комфортно в своей одежде. Мы стремимся подчеркнуть естественную красоту каждой женщины и помочь ей создать образ, который будет соответствовать ее личности и стилю жизни. Мы убеждены, что красота должна быть доступной каждому, поэтому мы предлагает широкий ассортимент моделей и размеров, чтобы каждая женщина могла найти что-то для себя.`,
    },
    {
      id: 5,
      img: "/assets/images/residents/5.png",
      fio: "Соколов Сергей Александрович",
      jobTitle: "Генеральный директор",
      company: "ГК ООО СЕРПРЕГИОНГАЗ",
      description: `Группа компаний «СЕРПРЕГИОНГАЗ» - это организация, которая занимается полным циклом обслуживания, диагностики, ремонта и установки бытового газового оборудования. Наши собственные филиалы расположены в муниципальных образованиях Подмосковья и количество наших абонентов превышает 120 000 человек. Мы являемся социально ориентированной организацией, которая проводит социальные акции для ветеранов и лиц с ограниченными возможностями. Основная цель нашей компании - это обеспечение безопасного использования газового оборудования в быту, а также предотвращение аварий и ЧП, связанных с газом.`,
    },
    {
      id: 6,
      img: "/assets/images/residents/6.png",
      fio: "Соколова Анна Валериевна ",
      jobTitle: "Учредитель, коммерческий директор ",
      company:
        'ООО "Центр Восстановительной Медицины" медицина, физическая реабилитация ',
      description: `Мы - команда специалистов, которые помогают людям вернуть здоровье и полноценную жизнь, используя методы физической реабилитации и ЛФК. Наша цель - изменить представление о лечении спины и суставов в обществе. Мы помогаем пациентам избежать операций на грыжи и по смене суставов, а также вернуть здоровье после инсульта, травм и операций. Мы дорожим каждым клиентом, поэтому у нас лучшие врачи и самый большой зал кинезитерапии в России. Мы участвуем в государственных программах по социальной поддержке населения, потому что ценим здоровье каждого человека и стремимся сделать наши услуги доступными для широкой аудитории. `,
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }
}

const residentsStore = new ResidentsStore();
export default residentsStore;
