import React, { FC } from "react";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";

export type Resident = {
  id: number;
  img: string;
  fio: string;
  jobTitle: string;
  company: string;
  description: string;
};

export type ResidentProps = {
  resident: Resident;
  onPress: Function;
};

const ResidentCard: FC<ResidentProps> = ({ resident, onPress }) => {
  return (
    <Card>
      <div className="grid">
        <div className="col-12 md:col-4 flex justify-content-center">
          <Avatar
            image={resident.img}
            shape="circle"
            style={{ width: 200, height: 200 }}
          />
        </div>

        <div className="col-12 md:col-8">
          <div
            className="text-xl font-semibold text-yellow-600 cursor-pointer hover:text-yellow-500 inline-block"
            onClick={() => onPress(resident)}
          >
            {resident.fio}
          </div>

          <div className="text-yellow-400 text-sm mt-1">
            <span>{resident.jobTitle}</span> в компании «
            <span>{resident.company}</span>»
          </div>

          <div className="mt-2 text-base">{resident.description}</div>
        </div>
      </div>
    </Card>
  );
};

export default ResidentCard;
